import React from 'react';

// Assuming you have your assets in the public folder or are using some bundler like Webpack.
import defaultImage from './assets/no_image_available.jpg';

function CamImg({ src }) {
    const handleImageError = (e) => {
        e.target.src = defaultImage; // replace with the default image path
    };

    return (
        <div className="mb-3">
            <img src={src} alt="frame1" className="img-fluid" onError={handleImageError} />
        </div>
    );
}

export default CamImg;
