import React, { useState, useEffect } from 'react';

function TemperatureCard({ src, headerText }) {
    const [temperature, setTemperature] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        fetch(src)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setTemperature(data.temperature);
            })
            .catch(err => {
                console.error("Error fetching temperature:", err);
                setError(true);
            });
    }, [src]);

    const displayTemperature = error ? '-' : (temperature !== null ? `${temperature} °C` : 'Sæki...');

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col">
                    <div className="card mb-3">
                        <div className="card-header">{headerText}</div>
                        <h3 className="card-body text-center">
                            {displayTemperature}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TemperatureCard;
