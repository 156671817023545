import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import TemperatureCard from './TemperatureCard';
import CamImg from './CamImg';

function App() {
    const [nowstamp, setNowstamp] = useState(null);

    useEffect(() => {
        // Set current timestamp
        setNowstamp(Date.now());
    }, []);
     // The empty dependency array ensures this useEffect runs only once after component mounts.

    return (
        <div className="container mt-5">
        <div className="row">
        <div className="col">
            <TemperatureCard src="/c1/temp" headerText="Stofuhiti" />
        </div>
        <div className="col">
            <TemperatureCard temp="/c2/temp" headerText="Gesthúshiti" />
        </div>
        </div>
        <div className="row">
        <div className="col">
            <CamImg src="/c2/cam" />
            <CamImg src="/c3/cam" />
            <CamImg src={`/c1/cam?${nowstamp}`} />
        </div>    
        </div>
        </div>);
}

export default App;
